
import { productService } from "@/_services/product/product";
import { downloadSoldDocumentCsv } from "@/_services/document/document";
import { downloadSoldCodesCsv } from "@/_services/code/code";
import { downloadBlacklistUserCsv } from "@/_services/blackList/blacklist";
import { jackpotService } from "@/_services/jackpot/jackpot";
import { downloadRefundedPaymentCsv } from "@/_services/transaction/paymentService";

export const exportTypes = {
  sold_documents: {
    label: "Export des ventes de documents",
    fileNamePrefix: "Ventes_de_documents_",
    description: "Cet export contient la liste des documents vendus sur une période spécifique, incluant les produits associés, ainsi que le numéro de commande et l'utilisateur lié à chaque vente.",
    callback: downloadSoldDocumentCsv,
    sql: `SELECT document.id as documentId,
          document.doc_name as documentName,
          DATE_FORMAT(document.saleDate, '%d/%m/%Y %H:%i') AS saleDate,
          order.order_number as orderNumber,
      CASE orderSupplier.order_state
          WHEN 1 THEN 'En attente'
          WHEN 2 THEN 'Terminée'
          WHEN 5 THEN 'Remboursée'
          WHEN 8 THEN 'Jackpot_issue'
          ELSE '-'
      END as commandStatus,
      order_detail.product_name as productName,
      orderSupplier.supplier_name as supplierName,
      order_detail.frezed_product_price as sellingPrice,
      CONCAT(order_detail.product_tax, '%') as productTax,
      cpp.purchasing_price as purchasePrice,
      ce_user.firstname as firstName,
      ce_user.lastname  as lastName,
      ce_user.email as email
  FROM document
      INNER JOIN order_detail ON order_detail.id = document.order_detail_id
      INNER JOIN ce_variant_product cvp ON cvp.id = order_detail.new_variant_product_id   
      INNER JOIN ce_product_price cpp ON cpp.variant_product_id = cvp.id  
      INNER JOIN orderSupplier ON orderSupplier.id = order_detail.order_supplier_id
      INNER JOIN \`order\` ON \`order\`.id  = orderSupplier.order_id
      INNER JOIN ce_user ON ce_user.id  = \`order\`.user_id
  WHERE document.orderSupplier_id IS NOT NULL
      AND document.saleDate IS NOT NULL
      AND document.saleDate BETWEEN :startDate AND :endDate`
  },
  sold_products: {
    label: "Export des ventes par produit",
    hasSupplierFilter: true,
    fileNamePrefix: "Ventes_de_produits_",
    description: "Cet export contient la liste des produits vendus sur une période spécifique, avec des informations détaillées telles que le prix d'achat, le taux de TVA, le prix de vente à la date de vente, ainsi que le numéro de commande et l'utilisateur associé à chaque vente. Cette liste peut être filtrée par fournisseur.",
    callback: productService.downloadSoldProductsCsv,
    sql: `SELECT o.order_number as 'numero_commande',
      CASE os.order_state
          WHEN 1 THEN 'En attente'
          WHEN 2 THEN 'Expédiée'
          WHEN 5 THEN 'Remboursée'
          WHEN 8 THEN 'Jackpot_issue'
          ELSE 'En erreur'
      END AS 'etat',
      od.product_name as 'nom_produit',
      cs.name as 'fournisseur',
      od.product_quantity as 'quantite_produit',
      od.frezed_product_price as  'prix_produit',
      od.product_price  as 'prix_total',
      os.shipping_cost as 'frais_de_port',
      CONCAT(cu.firstname, ' ', cu.lastname) AS 'nom_utilisateur',
      o.created  as 'date_commande',
      cu.email as 'email_utilisateur'
  FROM
      order_detail od
  LEFT JOIN order_subvention os2 on
      os2.order_detail_id = od.id
  INNER JOIN orderSupplier os on
      os.id = od.order_supplier_id
  INNER JOIN \`order\` o on
      o.id = os.order_id
  INNER JOIN ce_supplier cs on
      cs.id = os.new_supplier_id
  INNER JOIN ce_user cu ON
      cu.id = o.user_id
  WHERE
      o.created BETWEEN :startDate AND :endDate
      AND cs.uuid = :supplier `,
  },
  sold_codes: {
    label: "Export des ventes de codes",
    fileNamePrefix: "ventes_de_codes_",
    description: "Cet export contient la liste des codes vendus sur une période spécifique, incluant les produits associés, ainsi que le numéro de commande et l'utilisateur lié à chaque vente.",
    sql: `SELECT code.id as codeId,
      code.name as codeName,
      DATE_FORMAT(code.saleDate, '%d/%m/%Y %H:%i') AS saleDate,
      order.order_number as orderNumber,
      CASE orderSupplier.order_state
          WHEN 1 THEN 'En attente'
          WHEN 2 THEN 'Terminée'
          WHEN 5 THEN 'Remboursée'
          WHEN 8 THEN 'Jackpot_issue'
          ELSE '-'
      END as commandStatus,
      order_detail.product_name as productName,
      orderSupplier.supplier_name as supplierName,
      order_detail.frezed_product_price as sellingPrice,
      CONCAT(order_detail.product_tax, '%') as productTax,
          cpp.purchasing_price as purchasePrice,
          ce_user.firstname as firstName,
          ce_user.lastname  as lastName,
          ce_user.email as email
  FROM code
      INNER JOIN order_detail ON order_detail.id = code.order_detail_id
      INNER JOIN ce_variant_product cvp ON cvp.id = order_detail.new_variant_product_id
      INNER JOIN ce_product_price cpp ON cpp.variant_product_id = cvp.id
      INNER JOIN orderSupplier ON orderSupplier.id = order_detail.order_supplier_id
      INNER JOIN \`order\` ON \`order\`.id  = orderSupplier.order_id
      INNER JOIN ce_user ON ce_user.id  = \`order\`.user_id
  WHERE code.orderSupplier_id IS NOT NULL
      AND code.saleDate IS NOT NULL
      AND code.saleDate BETWEEN :startDate AND :endDate`,
    callback: downloadSoldCodesCsv,
  },
  jackpot_vouchers: {
    label: "Export des ventes Jackpot",
    fileNamePrefix: "export_voucher_jackpot_",
    description: "Cet export contient une liste des voucher Jackpot vendus sur une période spécifique, incluant le voucher, le numéro de commande et les statuts associés. Cet export permet de croiser nos données de commandes avec celles du prestataire.",
    sql: `SELECT ce_jackpot_order_voucher.created_at, 
            ce_jackpot_order_voucher.reference, 
            ce_jackpot_order_voucher.state, 
            \`order\`.order_number 
        FROM ce_jackpot_order_voucher
        INNER JOIN order_detail ON ce_jackpot_order_voucher.order_detail_id = order_detail.id 
        INNER JOIN orderSupplier ON order_detail.order_supplier_id = orderSupplier.id 
        INNER JOIN \`order\` ON orderSupplier.order_id = \`order\`.id 
        WHERE ce_jackpot_order_voucher.created_at BETWEEN :startDate AND :endDate`,
    callback: jackpotService.downloadOrderVoucher,
  },
  ogloba_codes: {
    label: "Export des ventes Ogloba",
    fileNamePrefix: "export_codes_ogloba_",
    description: "Cet export contient une liste des codes Ogloba vendus sur une période spécifique, incluant le code, le numéro de commande et les statuts associés. Cet export permet de croiser nos données de commandes avec celles du prestataire.",
    sql: `SELECT ce_ogloba_voucher.created_at, 
            ce_ogloba_voucher.voucher_ref, 
            ce_ogloba_voucher.state, 
            \`order\`.order_number 
        FROM ce_ogloba_voucher 
        INNER JOIN order_detail ON ce_ogloba_voucher.order_detail_id = order_detail.id 
        INNER JOIN orderSupplier ON order_detail.order_supplier_id = orderSupplier.id 
        INNER JOIN \`order\` ON orderSupplier.order_id = \`order\`.id 
        WHERE ce_ogloba_voucher.created_at BETWEEN :startDate AND :endDate`,
    callback: jackpotService.downloadOglobaVoucher,
  },
  payment_refunded: {
    label: "Export des transactions remboursées",
    fileNamePrefix: "Export_remboursements_transactions_",
    description: "Cet export contient la liste des transactions remboursées manuellement par l’admin, leur détail ainsi que les informations liées aux commandes associées.",
    callback: downloadRefundedPaymentCsv,
  },
  user_blacklist: {
    label: "Export des utilisateurs blacklisté",
    fileNamePrefix: "user_blacklist_",
    description: "Cet export contient la liste des utilisateurs blacklisté dans un intervalle de temps",
    sql: `SELECT DISTINCT
              user.id AS userId,
              CONCAT(user.firstname, ' ', user.lastname) AS userFullname,
              user.email AS userEmail,
              company.name AS companyName,
              DATE_FORMAT(userBlacklist.updated_at, '%Y-%m-%d %H:%i') AS lastBlacklistDate,
              DATE_FORMAT(userBlacklist.updated_at, '%Y-%m-%d %H:%i') AS englishlastBlacklistDate,
              userBlacklistDetailLatest.reason AS lastBlackListReason
          FROM ce_user_blacklist userBlacklist
              INNER JOIN ce_user user ON user.id = userBlacklist.user_id
              INNER JOIN company company ON company.id = user.company_id
              INNER JOIN (
                      SELECT subDetails.user_black_list_id AS user_black_list_id, subDetails.reason AS reason
                      FROM ce_user_black_list_details subDetails
                      WHERE subDetails.created_at = (
                      SELECT MAX(subSubDetails.created_at)
                      FROM ce_user_black_list_details subSubDetails
                      WHERE subSubDetails.user_black_list_id = subDetails.user_black_list_id
                  )
              ) userBlacklistDetailLatest ON userBlacklistDetailLatest.user_black_list_id = userBlacklist.id
          WHERE userBlacklist.active = 1
            AND user.enabled = 0
            AND user.status = 'rejected'
            AND DATE(userBlacklist.updated_at) BETWEEN :startDate AND :endDate
          ORDER BY englishlastBlacklistDate DESC`,
    callback: downloadBlacklistUserCsv,
  }
};