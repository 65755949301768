
export const exportTypesSql = {
    sold_documents: `SELECT document.id as documentId,
          document.doc_name as documentName,
          DATE_FORMAT(document.saleDate, '%d/%m/%Y %H:%i') AS saleDate,
          order.order_number as orderNumber,
      CASE orderSupplier.order_state
          WHEN 1 THEN 'En attente'
          WHEN 2 THEN 'Terminée'
          WHEN 5 THEN 'Remboursée'
          WHEN 8 THEN 'Jackpot_issue'
          ELSE '-'
      END as commandStatus,
      order_detail.product_name as productName,
      orderSupplier.supplier_name as supplierName,
      order_detail.frezed_product_price as sellingPrice,
      CONCAT(order_detail.product_tax, '%') as productTax,
      cpp.purchasing_price as purchasePrice,
      ce_user.firstname as firstName,
      ce_user.lastname  as lastName,
      ce_user.email as email
  FROM document
      INNER JOIN order_detail ON order_detail.id = document.order_detail_id
      INNER JOIN ce_variant_product cvp ON cvp.id = order_detail.new_variant_product_id   
      INNER JOIN ce_product_price cpp ON cpp.variant_product_id = cvp.id  
      INNER JOIN orderSupplier ON orderSupplier.id = order_detail.order_supplier_id
      INNER JOIN \`order\` ON \`order\`.id  = orderSupplier.order_id
      INNER JOIN ce_user ON ce_user.id  = \`order\`.user_id
  WHERE document.orderSupplier_id IS NOT NULL
      AND document.saleDate IS NOT NULL
      AND document.saleDate BETWEEN :startDate AND :endDate`
    ,
    sold_products: `SELECT o.order_number as 'numero_commande',
      CASE os.order_state
          WHEN 1 THEN 'En attente'
          WHEN 2 THEN 'Expédiée'
          WHEN 5 THEN 'Remboursée'
          WHEN 8 THEN 'Jackpot_issue'
          ELSE 'En erreur'
      END AS 'etat',
      od.product_name as 'nom_produit',
      cs.name as 'fournisseur',
      od.product_quantity as 'quantite_produit',
      od.frezed_product_price as  'prix_produit',
      od.product_price  as 'prix_produit_paye_par_l_utilisateur',
      os.shipping_cost as 'frais_de_port',
      CONCAT(cu.firstname, ' ', cu.lastname) AS 'nom_utilisateur',
      o.created  as 'date_commande',
      cu.email as 'email_utilisateur'
  FROM
      order_detail od
  LEFT JOIN order_subvention os2 on
      os2.order_detail_id = od.id
  INNER JOIN orderSupplier os on
      os.id = od.order_supplier_id
  INNER JOIN \`order\` o on
      o.id = os.order_id
  INNER JOIN ce_supplier cs on
      cs.id = os.new_supplier_id
  INNER JOIN ce_user cu ON
      cu.id = o.user_id
  WHERE
      o.created BETWEEN :startDate AND :endDate
      AND cs.uuid = :supplier `
    ,
    sold_codes: `SELECT code.id as codeId,
      code.name as codeName,
      DATE_FORMAT(code.saleDate, '%d/%m/%Y %H:%i') AS saleDate,
      order.order_number as orderNumber,
      CASE orderSupplier.order_state
          WHEN 1 THEN 'En attente'
          WHEN 2 THEN 'Terminée'
          WHEN 5 THEN 'Remboursée'
          WHEN 8 THEN 'Jackpot_issue'
          ELSE '-'
      END as commandStatus,
      order_detail.product_name as productName,
      orderSupplier.supplier_name as supplierName,
      order_detail.frezed_product_price as sellingPrice,
      CONCAT(order_detail.product_tax, '%') as productTax,
          cpp.purchasing_price as purchasePrice,
          ce_user.firstname as firstName,
          ce_user.lastname  as lastName,
          ce_user.email as email
  FROM code
      INNER JOIN order_detail ON order_detail.id = code.order_detail_id
      INNER JOIN ce_variant_product cvp ON cvp.id = order_detail.new_variant_product_id
      INNER JOIN ce_product_price cpp ON cpp.variant_product_id = cvp.id
      INNER JOIN orderSupplier ON orderSupplier.id = order_detail.order_supplier_id
      INNER JOIN \`order\` ON \`order\`.id  = orderSupplier.order_id
      INNER JOIN ce_user ON ce_user.id  = \`order\`.user_id
  WHERE code.orderSupplier_id IS NOT NULL
      AND code.saleDate IS NOT NULL
      AND code.saleDate BETWEEN :startDate AND :endDate`
    ,
    jackpot_vouchers: `SELECT ce_jackpot_order_voucher.created_at, 
        ce_jackpot_order_voucher.reference, 
        ce_jackpot_order_voucher.state, 
        \`order\`.order_number
    FROM 
        ce_jackpot_order_voucher 
    INNER JOIN 
        order_detail ON 
        ce_jackpot_order_voucher.order_detail_id = order_detail.id 
    INNER JOIN 
        orderSupplier ON 
        order_detail.order_supplier_id = orderSupplier.id 
    INNER JOIN 
        \`order\` ON 
        orderSupplier.order_id = \`order\`.id 
    WHERE 
        ce_jackpot_order_voucher.created_at BETWEEN :startDate AND :endDate`
    ,
    ogloba_codes: `SELECT ce_ogloba_voucher.created_at, 
        ce_ogloba_voucher.voucher_ref, 
        ce_ogloba_voucher.state, 
        \`order\`.order_number 
    FROM 
        ce_ogloba_voucher 
    INNER JOIN 
        order_detail 
        ON ce_ogloba_voucher.order_detail_id = order_detail.id 
    INNER JOIN 
        orderSupplier 
        ON order_detail.order_supplier_id = orderSupplier.id 
    INNER JOIN 
        \`order\` 
        ON orderSupplier.order_id = \`order\`.id 
    WHERE 
        ce_ogloba_voucher.created_at BETWEEN :startDate AND :endDate`
    ,
    payment_refunded: `SELECT 
        o.order_number AS 'order_number',
        lwp.lemonway_transaction_id AS 'lw_transaction_id_initial',
        CASE lwp.type
            WHEN 2 THEN 'CB'
            ELSE 'P2P'
        END AS 'cb_p2p',
        lwp.created_at AS 'initial_transaction_date',
        CASE lwr.order_state
            " . $stateCases . "
        END AS 'status_before_refund',
        lwr.created_at AS 'refund_date',
        lwr.amount AS 'amount',
        lwr.motive AS 'refund_motive',
        CASE lwr.is_partial_refund
            WHEN 1 THEN 'Complet'
            ELSE 'Partiel'
        END AS 'refund_type',
        CASE lwp.type
            WHEN 1 THEN 'WalletSC entreprise'
            ELSE 'User'
        END AS 'user_or_sc_wallet',
        lwr.lemonway_transaction_id AS 'lw_refund_id',
        aru.email AS 'refund_responsible_email',
        CASE
            WHEN COUNT(os.id) = 1 THEN 
                CASE MIN(os.order_state)
                    WHEN 0 THEN 'Plus de stock'
                    WHEN 1 THEN 'En préparation'
                    WHEN 2 THEN 'Livré'
                    WHEN 3 THEN 'Annulé par le client'
                    WHEN 4 THEN 'Envoi différé'
                    WHEN 5 THEN 'Remboursé'
                    WHEN 6 THEN 'Annulé'
                    WHEN 7 THEN 'Refusé'
                    WHEN 8 THEN 'Autre'
                    WHEN 9 THEN 'Erreur transfert'
                    WHEN 10 THEN 'Erreur remboursement'
                    WHEN 11 THEN 'livraison partiel'
                    ELSE ''
                END
            WHEN COUNT(DISTINCT os.order_state) = 1 THEN 
                CASE MIN(os.order_state)
                    WHEN 0 THEN 'Plus de stock'
                    WHEN 1 THEN 'En préparation'
                    WHEN 2 THEN 'Livré'
                    WHEN 3 THEN 'Annulé par le client'
                    WHEN 4 THEN 'Envoi différé'
                    WHEN 5 THEN 'Remboursé'
                    WHEN 6 THEN 'Annulé'
                    WHEN 7 THEN 'Refusé'
                    WHEN 8 THEN 'Autre'
                    WHEN 9 THEN 'Erreur transfert'
                    WHEN 10 THEN 'Erreur remboursement'
                    WHEN 11 THEN 'livraison partiel'
                    ELSE ''
                END
            ELSE 'Livré'
        END AS 'final_status',
        CASE lwp.status
            WHEN 0 THEN 'En cours'
            WHEN 1 THEN 'Validé'
            ELSE 'Invalide'
        END AS 'refund_status'
        FROM ce_lw_payment lwp
        INNER JOIN ce_lw_automatic_refund lwr on lwr.payment_id = lwp.id
        INNER JOIN ce_lw_transaction lwt ON lwt.id = lwp.transaction_id
        INNER JOIN \`order\` o on o.new_cart_id = lwt.cart_id
        INNER JOIN ce_user aru on lwr.user_id = aru.id
        LEFT JOIN orderSupplier os on os.order_id = o.id
        WHERE
            lwr.is_manual_refund = 1 AND lwr.created_at BETWEEN :startDate AND :endDate 
        GROUP BY o.order_number, lwp.lemonway_transaction_id, lwp.type, lwp.created_at, lwr.order_state, lwr.created_at, lwr.amount, lwr.motive, lwr.is_partial_refund, lwr.lemonway_transaction_id, aru.email, lwp.status
    `,
    user_blacklist:`SELECT DISTINCT
        user.id AS userId,
        CONCAT(user.firstname, ' ', user.lastname) AS userFullname,
        user.email AS userEmail,
        company.name AS companyName,
        DATE_FORMAT(userBlacklist.updated_at, '%Y-%m-%d %H:%i') AS lastBlacklistDate,
        DATE_FORMAT(userBlacklist.updated_at, '%Y-%m-%d %H:%i') AS englishlastBlacklistDate,
        userBlacklistDetailLatest.reason AS lastBlackListReason
    FROM ce_user_blacklist userBlacklist
    INNER JOIN ce_user user ON user.id = userBlacklist.user_id
    INNER JOIN company company ON company.id = user.company_id
    INNER JOIN (
        SELECT subDetails.user_black_list_id AS user_black_list_id, subDetails.reason AS reason
        FROM ce_user_black_list_details subDetails
        WHERE subDetails.created_at = (
            SELECT MAX(subSubDetails.created_at)
            FROM ce_user_black_list_details subSubDetails
            WHERE subSubDetails.user_black_list_id = subDetails.user_black_list_id
        )
    ) userBlacklistDetailLatest ON userBlacklistDetailLatest.user_black_list_id = userBlacklist.id
        WHERE userBlacklist.active = 1
            AND user.enabled = 0
            AND user.status = 'rejected'
            AND DATE(userBlacklist.updated_at) BETWEEN :startDate AND :endDate
        ORDER BY englishlastBlacklistDate DESC`
};